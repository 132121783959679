<template>
	<div class="app-container">
		<div class="filter-container">
            <!-- 关键字搜索 -->
            <div class="filter-item">
                <label class="label">活动名称: </label>
                <el-input v-model="searchData.activityName" clearable style='width:200px' placeholder="请输入活动名称"></el-input>
            </div>
            <div class="filter-item">
                <label class="label" style="margin-left: 20px;">活动状态:</label>
                <el-select v-model="searchData.activeType" placeholder="请选择" filterable clearable style="width: 160px;margin-right: 10px;">
                    <el-option v-for="(item,index) in activityStateList" :key="index" :label="item.str" :value="item.state"></el-option>
                </el-select>
            </div>
            <div class="filter-item">
                <label class="label">活动编码: </label>
                <el-input v-model="searchData.activityCode" clearable style='width:200px' placeholder="请输入活动编码"></el-input>
            </div>
            <!-- 时间筛选 -->
            <div class="filter-item">
                <label class="label" style="margin-left: 10px;">活动时间:</label>
                <el-date-picker v-model="applyTime" type="datetimerange" range-separator="～" start-placeholder="开始日期"
                end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </div>
            <!-- button -->
            <div class="filter-item">
                <el-button type="primary" style="width:100px;margin-right:20px;" @click="search()">搜索</el-button>
                <el-button type="primary" style="width:100px;margin-right:20px;" @click="clearSearch()">清空</el-button>
                <button-permissions :datas="'createDraw'">
                    <el-button type="warning" style="width:100px;margin-right:20px;" @click="handleDetailShow({id:0},0)">创建活动</el-button>
                </button-permissions>
            </div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="activityCode" label="活动编码"></el-table-column>
				<el-table-column label="活动名称">
					<template slot-scope="scope">
						<div>{{scope.row.activityName}}</div>
					</template>
				</el-table-column>
                <el-table-column label="活动类型">
					<template slot-scope="scope">
						<div>{{scope.row.activityType == 0 ? '不做限制' : scope.row.activityType == 1 ? '下单抽奖' : scope.row.activityType == 2 ? '新用户注册' : '下单+邀请'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="活动时间">
					<template slot-scope="scope">
						<div>{{scope.row.beginDate}}至</div>
						<div>{{scope.row.endDate}}</div>
					</template>
				</el-table-column>
				<el-table-column label="参与次数">
					<template slot-scope="scope">
						<div>{{scope.row.luckDrawCount>0?scope.row.luckDrawCount:'————'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="stateValue" label="活动状态"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<button-permissions :datas="'drawData'">
							<div class="table-button" @click="handleDataShow(scope.row)">数据</div>
						</button-permissions>
						<button-permissions :datas="'drawEdit'">
							<div v-if="scope.row.activityStatus!=2" class="table-button" @click="handleDetailShow(scope.row,1)">编辑</div>
						</button-permissions>
						<button-permissions :datas="'drawDel'">
							<div class="table-button" @click="sureDel(scope.row,2)">删除</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="searchData.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="searchData.pageNo" :page-sizes="[10, 20, 30, 40, 50]"
			:page-size="searchData.size" layout="total, sizes, prev, pager, next, jumper" :total="searchData.total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		drawactivityList,
		drawdelActivity,
	} from '@/api/goods.js'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				applyTime: '', //时间
				searchData: {
                    activityCode:'',    //活动编码
					activityName: '', //活动名称
					applyStartTime: '', //申请开始时间
					applyEndTime: '', //申请结束时间
					total: 0,
					pageNo: 1,
					size: 20,
					activeType: null,
				},
				loading: false,
				tableData: [],
				activityStateList: [{
						state: null,
						str: '全部'
					},
					{
						state: 0,
						str: '未开始'
					},
					{
						state: 1,
						str: '进行中'
					},
					{
						state: 2,
						str: '已结束'
					},
				],
			}
		},
		async beforeMount() {
			this.getList();
		},
		computed: {

		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					if(this.applyTime){
						this.searchData.applyStartTime = this.applyTime[0];
						this.searchData.applyEndTime = this.applyTime[1];
					}else{
						this.searchData.applyStartTime = '';
						this.searchData.applyEndTime = '';
					}
					let data = {
						pageNo: this.searchData.pageNo,
						pageSize: this.searchData.size,
						activityName: this.searchData.activityName,
						activityStatus:this.searchData.activeType,
						beginDate:this.searchData.applyStartTime,
						endDate:this.searchData.applyEndTime,
                        activityCode:this.searchData.activityCode,
					};
					let result = '';
					result = await drawactivityList(data);
					if(result.data){
						result.data.records.map(item => {
							//活动状态 0-未开始 1-进行中 2-已结束
							item.stateValue = item.activityStatus == 0 ? '未开始' : (item.activityStatus == 1 ? '进行中' :'已结束');
							return item;
						})
						this.tableData = result.data.records;
						this.searchData.total = result.data.total;
					}
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			//搜索
			search() {
				this.searchData.pageNo = 1
				this.searchData.isDownload = false; //下载文件标记
				if (!this.searchData.total) {
					this.searchData.size = 20
				}
				this.getList()
			},
			//跳转至详情
			handleDetailShow(row, type) {
				//1是编辑 0是创建
				let params = {
					id: row.id || null,
					type:type,
					isContinue:(row.id==0 || (row.id!=0&&row.activityStatus!=1))?0:1
				}
				this.publicToDetailsR(params, '/market/luckDraw/drawCreate', this);
			},
			handleDataShow(row) {
				let params = {
					id: row.id,
				}
				this.publicToDetailsR(params, '/market/luckDraw/drawRecord', this);
			},
			async handleFinish(row) {
				let data = {
					id: row.id
				};
				let res = await drawdelActivity(data);
				if (res.code == 200) {
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: "删除成功"
						});
						this.getList();
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.searchData.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.searchData.pageNo = val;
				this.getList();
			},
			sureDel(obj) {
				this.$alert('确定删除所选组合活动吗', '温馨提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: action => {
						if (action == 'confirm') {
							this.handleFinish(obj);
						}
					}
				});
			},
			clearSearch(){
				this.searchData = {
					activityName: '', //活动名称
					applyStartTime: '', //开始时间
					applyEndTime: '', //结束时间
					total: 0,
					pageNo: 1,
					size: 20,
					activeType:null
				}
				this.applyTime = '';
				this.search();
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	/deep/.el-table__fixed-body-wrapper {
		background: #ffffff;
	}

	/deep/.el-table__fixed-header-wrapper {
		background: #f8f8f9;
	}
</style>
